import { Loader } from 'src/shared/ui/Loader'
import styles from './PageLoading.module.scss'

export const PageLoading = () => {
	return (
		<div className={styles.pageLoader}>
			<Loader width="100px" color="grey" />
		</div>
	)
}
