import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
// TODO: Check if it works after deleting apollo
export const GlobalError = () => {
	const { pathname, hash } = useLocation()

	useEffect(() => {
		const handleGlobalErrors = (event) => {
			if (event.type === 'graphQLError') {
				enqueueSnackbar('Something went wrong. Please, check the console for details', {
					variant: 'error'
				})
			}
		}

		window.addEventListener('graphQLError', handleGlobalErrors)

		return () => {
			window.removeEventListener('graphQLError', handleGlobalErrors)
		}
	}, [])

	useEffect(() => {
		closeSnackbar()
	}, [pathname, hash])

	return null
}
