import { useEffect } from 'react'
import { enqueueSnackbar } from 'notistack'
import { useBackgroundActivity } from 'src/shared/store'
import { useMutation } from '@tanstack/react-query'
import { graphClient } from 'src/app/graphql/client'
import { TASK_STATUS, TaskStatusResponse } from 'src/shared/api/dashboard'

const BackgroundExport: React.FC = () => {
	const state = useBackgroundActivity()

	const { mutateAsync } = useMutation<TaskStatusResponse, unknown, { id: string }>({
		mutationFn: (vars) => graphClient.request(TASK_STATUS, vars)
	})

	const handleTaskStatus = (exportId: string, action: () => void) => {
		return setInterval(async () => {
			const data = await mutateAsync({
				id: exportId
			})

			if (data.taskStatus.status === 'FAILURE') {
				enqueueSnackbar('Failed to download file. Please try again.', {
					variant: 'error'
				})
				action()

				return
			}

			if (data.taskStatus.status === 'SUCCESS') {
				const link = document.createElement('a')

				link.href = data.taskStatus.url
				document.body.appendChild(link)
				link.click()
				link.parentNode.removeChild(link)

				action()
			}
		}, 1000)
	}

	useEffect(() => {
		let interval: NodeJS.Timer

		if (state.parentExportId) {
			interval = handleTaskStatus(state.parentExportId, state.clearParentExportId)
		}

		return () => {
			clearInterval(interval)
		}
	}, [state.parentExportId])

	useEffect(() => {
		let interval: NodeJS.Timer

		if (state.reportingExportId) {
			interval = handleTaskStatus(state.reportingExportId, state.clearReportingExportId)
		}

		return () => {
			clearInterval(interval)
		}
	}, [state.reportingExportId])

	return null
}

export default BackgroundExport
