import { graphql } from 'msw'
import dashboardProfile from './fixtures/dashboardProfile.json'
import updateProfileDashboard from './fixtures/updateProfile.json'

const editPolicy = [
	graphql.query('dashboardProfile', (_, res, ctx) => res(ctx.data(dashboardProfile))),
	graphql.mutation('updateProfileDashboard', (req, res, ctx) => {
		const { name } = req.variables
		if (!name) return res(ctx.data(updateProfileDashboard.error))
		return res(ctx.data(updateProfileDashboard.ok))
	})
]

export default editPolicy
