import { graphql } from 'msw'
import parents from './fixtures/parents.json'
import threeDotMenu from './fixtures/threeDotMenu.json'
import child from './fixtures/child.json'
import policies from './fixtures/policies.json'
import addParent from './fixtures/addParent.json'

const adminDashboardParents = [
	graphql.query('parents', (req, res, ctx) => {
		const { orderBy, sortBy, status, search } = req.variables

		if (search) {
			return res(
				ctx.data(
					search.toLowerCase() === 'andrew'
						? parents.data.searchField
						: parents.data.emptySearch
				)
			)
		}

		if (status === 'PENDING') {
			return res(ctx.data(parents.data.pendingStatus))
		}

		if (status === 'ACTIVATED') {
			return res(ctx.data(parents.data.loggedStatus))
		}

		if (orderBy === 'NAME') {
			return res(ctx.data(sortBy === 'ASC' ? parents.data.ascName : parents.data.descName))
		}

		if (orderBy === 'EMAIL') {
			return res(ctx.data(sortBy === 'ASC' ? parents.data.ascEmail : parents.data.descEmail))
		}

		return res(ctx.data(parents.data.ascName))
	}),
	graphql.query('parents_quantity', (_, res, ctx) => {
		return res(ctx.data(parents.data.parents_quantity))
	}),
	graphql.mutation('createEmails', (req, res, ctx) => {
		const { emails } = req.variables

		if (emails[1] === 'test') {
			return res(ctx.data(threeDotMenu.data.invalid__email))
		}

		if (emails[1] === 'existing@gmail.com') {
			return res(ctx.data(threeDotMenu.data.existing__email))
		}

		return res(ctx.data(threeDotMenu.data.valid__email))
	}),
	graphql.mutation('updateParentDashboard', (_, res, ctx) => {
		return res(ctx.data(threeDotMenu.data.editParent))
	}),
	graphql.mutation('createExtraEmailsDashboard', (req, res, ctx) => {
		if (req.variables.emails[0] === 'existing@gmail.com') {
			return res(ctx.data(threeDotMenu.data.existing__extra__email))
		}

		return res(ctx.data(threeDotMenu.data.valid__extra__email))
	}),
	graphql.mutation('resendParentInvitation', (_, res, ctx) => {
		return res(ctx.data(threeDotMenu.data.resend__invitation))
	}),
	graphql.mutation('resendParentsInvitation', (_, res, ctx) => {
		return res(ctx.data(threeDotMenu.data.resend__invitations))
	}),
	graphql.mutation('deleteParentDashboard', (_, res, ctx) => {
		return res(ctx.data(threeDotMenu.data.delete__parent))
	}),
	graphql.query('childDashboard', (_, res, ctx) => {
		return res(ctx.data(child.data.child))
	}),
	graphql.mutation('updateChildDashboard', (_, res, ctx) => {
		return res(ctx.data(child.data.update__child))
	}),
	graphql.mutation('deleteChildDashboard', (_, res, ctx) => {
		return res(ctx.data(child.data.delete__child))
	}),
	graphql.query('policies', (_, res, ctx) => {
		return res(ctx.data(policies.data))
	}),
	graphql.query('timeIntervalList', (_, res, ctx) => {
		return res(ctx.data(child.data.time__intervals))
	}),
	graphql.query('parentsExport', (_, res, ctx) => {
		return res(ctx.data(parents.data.parents__export))
	}),
	graphql.query('taskStatus', (_, res, ctx) => {
		return res(ctx.data(parents.data.export__status))
	}),
	graphql.mutation('createParentDashboard', (req, res, ctx) => {
		const { email } = req.variables

		if (email === 'existing@email.com') {
			return res(ctx.data(addParent.data.create__parent__error))
		}

		return res(ctx.data(addParent.data.create__parent))
	}),
	graphql.mutation('createInProgressLicences', (_, res, ctx) => {
		return res(ctx.data(addParent.data.create__licence))
	}),
	graphql.mutation('createChildDashboard', (_, res, ctx) => {
		return res(ctx.data(addParent.data.create__child))
	}),
	graphql.query('parent', (_, res, ctx) => {
		return res(ctx.data(addParent.data.get__single__parent))
	}),
	graphql.mutation('createParentCsvDashboard', (req, res, ctx) => {
		const { csvFile } = req.variables

		if (csvFile.size === 5) {
			return res(ctx.data(addParent.data.upload__CSV__with__error))
		}

		return res(ctx.data(addParent.data.upload__CSV))
	})
]
export default adminDashboardParents
