import { create } from 'zustand'

type State = {
	parentExportId: string
	reportingExportId: string
}

type Actions = {
	setParentExportId: (parentExportId: string) => void
	clearParentExportId: () => void
	setReportingExportId: (reportingExportId: string) => void
	clearReportingExportId: () => void
}

export const useBackgroundActivity = create<State & Actions>((set) => ({
	parentExportId: '',
	reportingExportId: '',
	setParentExportId: (parentExportId: string) => set(() => ({ parentExportId })),
	clearParentExportId: () => set(() => ({ parentExportId: '' })),
	setReportingExportId: (reportingExportId: string) => set(() => ({ reportingExportId })),
	clearReportingExportId: () => set(() => ({ reportingExportId: '' }))
}))
