import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { graphClient } from 'src/app/graphql/client'
import { siteInfoKey, GET_SITE_INFO, SiteInfoResponse } from 'src/shared/api/common'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetUserResponse, IS_AUTHENTICATED, userkeys } from 'src/shared/api/users'
import ROUTES_NAME from 'src/shared/router/routesNames'

export const Metadata = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const { data } = useQuery({
		queryKey: [siteInfoKey.all],
		queryFn: () => graphClient.request<SiteInfoResponse>(GET_SITE_INFO)
	})
	const { data: userData, isLoading } = useQuery<GetUserResponse>({
		queryKey: [userkeys.user],
		queryFn: () => graphClient.request(IS_AUTHENTICATED)
	})

	useEffect(() => {
		if (data?.siteInfo?.zendeskApiKey) {
			const script = document.createElement('script')
			script.src = `https://static.zdassets.com/ekr/snippet.js?key=${data.siteInfo.zendeskApiKey}`
			script.id = 'ze-snippet'
			document.body.appendChild(script)
		}
	}, [data])

	useEffect(() => {
		if (!isLoading && userData?.user.isAuthenticated && !userData.user.isEmailVerified) {
			navigate(
				userData?.user.isAdmin
					? ROUTES_NAME.ADMIN_REGISTRATION
					: ROUTES_NAME.PARENT_REGISTRATION
			)
		}
	}, [userData, isLoading, pathname])

	return null
}
