import { graphql } from 'msw'
import admins from './fixtures/admins.json'

const adminDashboardAdmins = [
	graphql.query('getAdmins', (req, res, ctx) => {
		const { sortBy, search } = req.variables

		if (search) {
			return res(
				ctx.data(
					search.toLowerCase() === 'morgan'
						? admins.data.search__admin
						: admins.data.empty__search
				)
			)
		}

		if (sortBy === 'DESC') {
			return res(ctx.data(admins.data.descSort))
		}

		return res(ctx.data(admins.data.default))
	}),
	graphql.query('schools', (_, res, ctx) => {
		return res(
			ctx.data({
				schools: {
					quantity: 2,
					school: [
						{ id: '1', name: 'Schools Mobile', __typename: 'SchoolType' },
						{ id: '2', name: 'School2', __typename: 'SchoolType' }
					],
					__typename: 'SchoolsType'
				}
			})
		)
	}),
	graphql.mutation('createAdminDashboard', (req, res, ctx) => {
		const { email } = req.variables

		if (email === 'existing@email.com') {
			return res(
				ctx.data({
					createAdminDashboard: {
						errors: [
							{
								fieldName: 'email',
								errors: ['User with this email already exists.'],
								__typename: 'BaseErrorType'
							}
						],
						ok: false,
						user: null,
						__typename: 'CreateAdminDashboardMutation'
					}
				})
			)
		}

		return res(
			ctx.data({
				createAdminDashboard: {
					errors: [],
					ok: true,
					user: {
						id: 214,
						__typename: 'AdminType'
					}
				}
			})
		)
	})
]

export default adminDashboardAdmins
