import gql from 'graphql-tag'

export const GET_SITE_INFO = gql`
	query siteInfo {
		siteInfo {
			logo
			titleSection1
			subtitleSection1
			subtitleSection1IsHtml
			titleSection2
			subtitleSection2
			titleSection3
			titleSection4
			subtitleSection4
			policies
			contactUs
			installInstructions
			faqs
			readMore
			protectThing1
			protectThing2
			protectThing3
			protectThing4
			orgName
			timeZone
			zendeskApiKey
		}
	}
`

export const GET_LICENSE_PRICE = gql`
	query licencePrice {
		licencePrice {
			currency
			price
			recurringInterval
		}
	}
`
export const CHECK_VALID_LICENSE = gql`
	query licenceIsValid($codes: [String]) {
		licenceIsValid(codes: $codes) {
			isValid
			code
			error
		}
	}
`

export const GET_COUNTRIES = gql`
	query countries($page: Int, $perPage: Int, $sortBy: SortOrder, $search: String) {
		countries(page: $page, perPage: $perPage, sortBy: $sortBy, search: $search) {
			countries {
				id
				name
				icon
			}
			quantity
		}
	}
`

export const GET_IN_PROGRESS_LICENCES = gql`
	query getInProgressLicences {
		user {
			id
			licences(inProgress: true) {
				code
				status
				school {
					name
				}
				licenceType
			}
		}
	}
`

export const GET_POLICIES = gql`
	query policies {
		policies {
			name
			id
		}
	}
`

export const GET_CHILDREN_LICENCES = gql`
	query children($sortBy: UserQueryLicenceStatus) {
		children(sortBy: $sortBy) {
			name
			licence {
				code
				status
				licenceType
				profileCode
			}
		}
	}
`

export const SCHOOLS = gql`
	query schools($page: Int, $perPage: Int, $search: String) {
		schools(page: $page, perPage: $perPage, search: $search) {
			school {
				id
				name
			}
			quantity
		}
	}
`

export const SCHOOLS_COUNT = gql`
	query schools($page: Int, $perPage: Int, $search: String) {
		schools(page: $page, perPage: $perPage, search: $search) {
			quantity
		}
	}
`
