import { graphql } from 'msw'

const adminRegistration = [
	graphql.mutation('registerAdmin', (req, res, ctx) => {
		const { schoolPolicy, termsOfUse, password1 } = req.variables

		if (!schoolPolicy && !termsOfUse && password1 === 'some__password') {
			return res(
				ctx.data({
					registerAdmin: {
						ok: false,
						errors: [
							{
								errors: ['School policy is not accepted.'],
								fieldName: 'schoolPolicy',
								__typename: 'BaseErrorType'
							},
							{
								errors: ['Terms of use is not accepted.'],
								fieldName: 'termsOfUse',
								__typename: 'BaseErrorType'
							},
							{
								errors: [
									'Password must contain at least one capital letter and digit'
								],
								fieldName: 'password1',
								__typename: 'BaseErrorType'
							}
						],
						user: null,
						__typename: 'RegisterAdminMutation'
					}
				})
			)
		}

		return res(
			ctx.data({
				registerAdmin: {
					ok: true,
					errors: [],
					user: {
						firstName: 'Name',
						lastName: 'Last',
						id: 412,
						isSuperadmin: false,
						isAdmin: true,
						isAuthenticated: true,
						isEmailVerified: true,
						postCode: null,
						country: null,
						city: null,
						phoneNumber: null,
						address: null,
						email: 'not.full.admin@gmail.com',
						licences: null,
						isExtra: null,
						hasExtra: null,
						extraEmails: null,
						__typename: 'UserType'
					},
					__typename: 'RegisterAdminMutation'
				}
			})
		)
	})
]

export default adminRegistration
