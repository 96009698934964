import { AwesomeGraphQLClient } from 'awesome-graphql-client'
import { print } from 'graphql/language/printer'
import { QueryClient, QueryCache } from '@tanstack/react-query'
import { DocumentNode } from 'graphql'

export const graphClient = new AwesomeGraphQLClient({
	endpoint:
		process.env.REACT_APP_MODE === 'develop'
			? process.env.REACT_APP_API_URL
			: `${window.location.origin}/graphql/`,
	formatQuery: (query: DocumentNode | string) =>
		typeof query === 'string' ? query : print(query),
	fetch: (url, options) => fetch(url, { ...options, credentials: 'include' })
})

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 1000 * 20,
			enabled: !localStorage.getItem('underMaintenance')
		}
	},
	queryCache: new QueryCache({
		onError: (_, query) => {
			const statusCode = (query.state.fetchFailureReason as { response: { status: number } })
				.response?.status

			if ([500, 501, 502, 503].includes(statusCode)) {
				localStorage.setItem('underMaintenance', new Date().getTime().toString())
				window.location.replace(`${window.location.origin}/under-maintenance`)
			}
		}
	})
})
