import { graphql } from 'msw'
import licencePrice from './fixtures/licencePrice.json'

const dashboardAddLicence = [
	graphql.query('licencePrice', (_, res, ctx) => res(ctx.data(licencePrice))),
	graphql.mutation('deleteNewExtraLicences', (_, res, ctx) =>
		res(
			ctx.data({
				deleteNewExtraLicences: {
					ok: true
				}
			})
		)
	)
]
export default dashboardAddLicence
