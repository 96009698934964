import { graphql } from 'msw'
import data from './fixtures/dashboardProfiles.json'
import baseProfile from './fixtures/baseProfile.json'

const adminDashboardPolicies = [
	graphql.query('dashboardProfiles', (req, res, ctx) => {
		const { orderBy, search, sortBy } = req.variables
		if (search) {
			return res(
				ctx.data(search.toLowerCase() === 'threat' ? data.searchField : data.emptySearch)
			)
		}

		if (orderBy === 'NAME') {
			return res(ctx.data(sortBy === 'ASC' ? data.ascName : data.descName))
		}

		if (orderBy === 'ISACTIVE') {
			return res(ctx.data(sortBy === 'ASC' ? data.ascIsActive : data.descIsActive))
		}
		if (orderBy === 'ORDERNUMBER') {
			return res(ctx.data(sortBy === 'ASC' ? data.ascOrderNumber : data.descOrderNumber))
		}
		if (orderBy === 'ISBASE') {
			return res(ctx.data(sortBy === 'ASC' ? data.ascIsBase : data.descIsBase))
		}

		return res(ctx.data(data.ascName))
	}),
	graphql.query('baseProfile', (_, res, ctx) => res(ctx.data(baseProfile))),
	graphql.mutation('createProfileDashboard', (req, res, ctx) => {
		const { name } = req.variables
		if (!name)
			return res(
				ctx.data({
					createProfileDashboard: {
						ok: false,
						errors: [
							{
								errors: ['Field is required'],
								fieldName: 'name',
								__typename: 'BaseErrorType'
							}
						],
						__typename: 'CreateProfileDashboardMutation'
					}
				})
			)
		return res(
			ctx.data({
				createProfileDashboard: {
					ok: true,
					errors: [],
					__typename: 'CreateProfileDashboardMutation'
				}
			})
		)
	})
]
export default adminDashboardPolicies
