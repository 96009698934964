import gql from 'graphql-tag'

export const FRAGMENT_USER = gql`
	fragment User on UserType {
		id
		postCode
		country {
			id
			name
			icon
		}
		city
		phoneNumber
		address
		firstName
		lastName
		address
		email
		licences(inProgress: true) {
			activationDate
			status
			code
			school {
				name
			}
			isValid
			profileCode
		}
		isAuthenticated
		isAdmin
		isSuperadmin
		isExtra
		hasExtra
		isEmailVerified
		extraEmails
	}
`
