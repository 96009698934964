import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import 'src/shared/styles/main.scss'
import { queryClient } from 'src/app/graphql/client'
import { QueryClientProvider } from '@tanstack/react-query'
import App from './App'
import './index.css'
import { worker } from './shared/mocks/browser'
import { Snackbar } from './shared/ui/Snackbar'
import BackgroundExport from './features/BackgroundExport'

const MainApp = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<SnackbarProvider
				anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
				maxSnack={1}
				Components={{
					error: Snackbar,
					success: Snackbar,
					default: Snackbar,
					info: Snackbar
				}}
			/>
			<BackgroundExport />
			<Router>
				<App />
			</Router>
		</QueryClientProvider>
	)
}

async function bootstrapApp() {
	if (process.env.REACT_APP_TEST_MODE) {
		await worker.start()
	}
	const root = createRoot(document.getElementById('root'))
	root.render(<MainApp />)
}
bootstrapApp()
