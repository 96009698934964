import { graphql } from 'msw'
import correct from './fixtures/correct.json'
import incorrect from './fixtures/incorrect.json'

const data = {
	'correct@gmail.com': correct.parent,
	'admin@gmail.com': correct.admin,
	'super.admin@gmail.com': correct.superadmin,
	'incorrect@gmail.com': incorrect,
	'not.full.user@gmail.com': correct.not_full_user,
	'not.full.admin@gmail.com': correct.not_full_admin
}

const auth = [
	graphql.mutation('loginUser', (req, res, ctx) => {
		const { email } = req.variables
		return res(ctx.data(data[email] || correct.parent))
	}),
	graphql.mutation('logoutUser', (_, res, ctx) => res(ctx.data(correct.logout)))
]
export default auth
