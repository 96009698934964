import { graphql } from 'msw'
import siteInfo from './fixtures/siteInfo.json'
import * as handlers from './handlers'

const mocks = [
	...Object.values(handlers).flat(),
	graphql.query('siteInfo', (_, res, ctx) => res(ctx.data(siteInfo))),
	graphql.query('getZendeskInfo', (_, res, ctx) =>
		res(
			ctx.data({
				siteInfo: {
					zendeskApiKey: 'r278s8d-4fgjfdg7f-dfe33ress'
				}
			})
		)
	)
]

export default mocks
