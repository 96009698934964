import { graphql } from 'msw'
import user from './fixtures/user.json'

const addLicenseHomePage = [
	graphql.mutation('deleteAnonymousUser', (_, res, ctx) =>
		res(
			ctx.data({
				deleteAnonymousUser: {
					ok: true
				}
			})
		)
	),
	graphql.query('user', (_, res, ctx) => res(ctx.data(user.user))),
	graphql.query('anonymousUser', (_, res, ctx) => res(ctx.data(user.anonymousUser))),
	graphql.query('licenceIsValid', (req, res, ctx) => {
		const { codes } = req.variables
		if (codes.includes('some code')) {
			return res(
				ctx.data({
					licenceIsValid: [
						{
							isValid: false,
							code: 'some code',
							error: 'Licence is not valid.',
							__typename: 'LicenceValidType'
						}
					]
				})
			)
		}
		return res(
			ctx.data({
				licenceIsValid: [
					{
						isValid: true,
						code: 'some code',
						error: 'Licence is not valid.',
						__typename: 'LicenceValidType'
					}
				]
			})
		)
	}),
	graphql.mutation('createAnonymousUser', (_, res, ctx) =>
		res(
			ctx.data({
				createAnonymousUser: {
					ok: true,
					errors: [],
					licenceIsValid: [],
					__typename: 'CreateAnonymousUserMutation'
				}
			})
		)
	)
]
export default addLicenseHomePage
