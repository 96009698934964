import { Suspense, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { addMinutes, isFuture } from 'date-fns'
import ROUTES_NAME from 'src/shared/router/routesNames'
import { useQueryClient } from '@tanstack/react-query'
import { PageLoading } from 'src/shared/ui/PageLoading'
import { Metadata } from './features/Metadata'
import { GlobalError } from './features/GlobalError'
import { PrivateRoutes } from './shared/guards/PrivateRoutes'
import { FinishRegisterRoutes } from './shared/guards/FinishRegisterRoutes'
import { routes } from './shared/router'

const App = () => {
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	useEffect(() => {
		const underMaintenance = localStorage.getItem('underMaintenance')

		if (isFuture(addMinutes(new Date(+underMaintenance), 2))) {
			queryClient.cancelQueries()
			navigate(ROUTES_NAME.UNDER_MAINTENANCE)
		}
	}, [])

	return (
		<>
			<Metadata />
			<GlobalError />
			<Suspense fallback={<PageLoading />}>
				<Routes>
					{routes.map(
						({ path, Element, privateRoute, checkFulfillment, superAdminOnly }) => {
							if (privateRoute) {
								return (
									<Route
										element={<PrivateRoutes superAdminOnly={superAdminOnly} />}
										key={path}
									>
										<Route path={path} element={<Element />} />
									</Route>
								)
							}
							if (checkFulfillment) {
								return (
									<Route element={<FinishRegisterRoutes />} key={path}>
										<Route path={path} element={<Element />} />
									</Route>
								)
							}
							return <Route path={path} element={<Element />} key={path} />
						}
					)}
				</Routes>
			</Suspense>
		</>
	)
}

export default App
