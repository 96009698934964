import { CustomSelectValue } from 'src/shared/ui/SimpleSelect/types'
import { ParentFilters } from '../types'

export const statusList: CustomSelectValue[] = [
	{ id: '', label: 'All statuses', value: 'default' },
	{ id: '1', label: 'Open', value: 'OPEN' },
	{ id: '2', label: 'In progress', value: 'IN_PROGRESS' },
	{ id: '3', label: 'Protected', value: 'PROTECTED' },
	{ id: '4', label: 'Unprotected', value: 'UNPROTECTED' },
	{ id: '5', label: 'Expired', value: 'EXPIRED' }
]

export const licenceTypeList: CustomSelectValue[] = [
	{ id: '', label: 'Paid/Pre-paid', value: 'default' },
	{ id: '1', label: 'Paid', value: 'EXTRA' },
	{ id: '2', label: 'Pre-paid', value: 'PRE_PAID' }
]

export const defaultFilters: ParentFilters = {
	school: { id: '', value: 'default', label: 'All schools' },
	policy: { id: '', value: 'default', label: 'All policies' },
	licenceStatus: { id: '', value: 'default', label: 'All statuses' },
	licenceType: { id: '', value: 'default', label: 'Paid/Pre-paid' }
}
