import { graphql } from 'msw'
import policies from './fixtures/policies.json'
import children from './fixtures/children.json'
import timeIntervals from './fixtures/timeIntervals.json'
import analytics_data from './fixtures/analytics.json'
import firstChild from './fixtures/firstChild.json'
import secondChild from './fixtures/secondChild.json'
import updateChild from './fixtures/updateChild.json'

const child = {
	'127': firstChild,
	'129': secondChild
}
const analytics = {
	'127': analytics_data.anna,
	'129': analytics_data.jimmy
}

const parentDashboard = [
	graphql.query('policies', (_, res, ctx) => res(ctx.data(policies))),
	graphql.query('children', (_, res, ctx) => res(ctx.data(children))),
	graphql.query('getInProgressLicences', (_, res, ctx) =>
		res(ctx.data({ user: { id: '135', licences: [] } }))
	),
	graphql.query('timeIntervals', (_, res, ctx) => res(ctx.data(timeIntervals))),
	graphql.query('analytics', (req, res, ctx) => {
		const { childId } = req.variables
		return res(ctx.data(analytics[childId] || analytics_data.all_children))
	}),
	graphql.query('child', (req, res, ctx) => {
		const { id } = req.variables
		return res(ctx.data(child[id] || firstChild))
	}),
	graphql.mutation('updateChild', (req, res, ctx) => {
		const { policyId, agreed } = req.variables
		if (policyId && policyId === 6 && !agreed) {
			return res(ctx.data(updateChild))
		}
		return res(
			ctx.data({
				updateChild: {
					ok: true,
					errors: []
				}
			})
		)
	}),
	graphql.mutation('setDayRecreationTime', (_, res, ctx) =>
		res(
			ctx.data({
				setDayRecreationTime: {
					ok: true,
					errors: []
				}
			})
		)
	)
]
export default parentDashboard
