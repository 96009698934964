import { graphql } from 'msw'
import children from './fixtures/children.json'
import analytics from './fixtures/analytics.json'

const adminDashboardReporting = [
	graphql.query('minLogDate', (_, res, ctx) => {
		return res(ctx.data({ minLogDate: '2023-07-02T09:41:20.724854+00:00' }))
	}),
	graphql.query('childrenSearch', (req, res, ctx) => {
		const { search } = req.variables

		if (search) {
			return res(
				ctx.data(
					search === 'Boris'
						? children.data.children__search
						: children.data.empty__search
				)
			)
		}

		return res(ctx.data(children.data.default__list))
	}),
	graphql.query('analyticsAdmin', (req, res, ctx) => {
		const { childId, fromData2, toData2 } = req.variables

		if (fromData2 && toData2) {
			return res(ctx.data(analytics.data.compare__mode))
		}

		if (childId) {
			return res(ctx.data(analytics.data.children__search))
		}

		return res(ctx.data(analytics.data.default))
	})
]

export default adminDashboardReporting
