import { useQuery } from '@tanstack/react-query'
import { Navigate, Outlet } from 'react-router-dom'
import { graphClient } from 'src/app/graphql/client'
import ROUTES_NAME from 'src/shared/router/routesNames'
import { IS_AUTHENTICATED } from '../api/users/queries'
import { GetUserResponse } from '../api/users'
import { userkeys } from '../api/users/keys'
import { PageLoading } from '../ui/PageLoading'

export const PrivateRoutes = ({ superAdminOnly }: { superAdminOnly?: boolean }): JSX.Element => {
	const { data, isLoading } = useQuery<GetUserResponse>({
		queryKey: [userkeys.user],
		queryFn: () => graphClient.request(IS_AUTHENTICATED)
	})

	const isLoggedIn = !isLoading && data?.user.isAuthenticated
	if (isLoading) {
		return <PageLoading />
	}

	if (superAdminOnly && !data?.user?.isSuperadmin) {
		return <Navigate to={ROUTES_NAME.DASHBOARD} />
	}

	return isLoggedIn ? <Outlet /> : <Navigate to={ROUTES_NAME.LOGIN} />
}
