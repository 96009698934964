import { createQueryKeyStore, createQueryKeys } from '@lukemorales/query-key-factory'

export const siteInfoKey = createQueryKeyStore({
	all: null
})

export const licencePriceKey = createQueryKeys('licencePrice')
export const checkValidLicenses = createQueryKeys('checkValidLicenses')
export const getInProgressLicensesKey = createQueryKeys('getInProgressLicences')
export const getPoliciesKey = createQueryKeys('policies')
export const createChildKey = createQueryKeys('createChild')
export const getChildrenLicencesKey = createQueryKeys('children')
