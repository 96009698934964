import { Navigate, Outlet } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { graphClient } from 'src/app/graphql/client'
import ROUTES_NAME from 'src/shared/router/routesNames'
import { GetUserResponse } from '../api/users'
import { userkeys } from '../api/users/keys'
import { IS_AUTHENTICATED } from '../api/users/queries'
import { PageLoading } from '../ui/PageLoading'

export const FinishRegisterRoutes = (): JSX.Element => {
	const { data, isLoading } = useQuery<GetUserResponse>({
		queryKey: [userkeys.user],
		queryFn: () => graphClient.request(IS_AUTHENTICATED)
	})
	const shouldRegister = !isLoading && !data.user.isEmailVerified
	const isInProgressLicence = data?.user.licences?.some(({ status }) => status === 'IN_PROGRESS')

	if (isLoading) {
		return <PageLoading />
	}

	if (!shouldRegister && !isInProgressLicence) {
		return <Navigate to={ROUTES_NAME.DASHBOARD} />
	}

	return <Outlet />
}
